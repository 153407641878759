import axios from 'axios';

//initial state
const state = {
    all: []
}

//getters
const getters = {
    getBlogById: (state) => (id) => {
        return state.all.find(all => all.id == id);
    }
}

//actions
const actions = {
    async getAllBlogs({ commit }) {
        await axios
            .get("/blogs")
            .then(response => (commit('setBlogs', response.data )));
    },
    async postNewBlog({ commit }, data) {
        await axios
            .post("/blogs", data, {
                headers: {
                    'Content-type': 'multipart/form-data'
                }
            })
            .then(response => (commit('setNewBlog', response.data )));
    },
    async getOneBlog({commit}, id) {
        let blog = state.all.find(all => all.id == id);
        if(blog) {
            return blog;
        } else {
            await axios
                .get("/blogs/" + id)
                .then(response => (commit('setBlogs', [response.data])));
            return false;
        }
    },
    async editBlog( {commit, state}, payload ) {
        await axios
            .post("/blogs/" + payload.id, payload.data, {
                headers: {
                    'Content-type': 'multipart/form-data'
                }
            })
            .then( response => (commit('setBlogs', response.data)));
    },
    async destroyBlog( {commit }, id) {
        await axios
            .delete("/blogs/" + id)
            .then(() => (commit('deleteBlog', id)));
    }
}

//mutations 
const mutations = {
    setBlogs (state, blogs) {
        state.all = blogs;
    },
    setNewBlog (state, data) {
        state.all.push(data);
    },
    deleteBlog(state, id) {
        let index = state.all.findIndex(all => all.id == id)
        state.all.splice(index, 1);
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}