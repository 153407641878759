<template>
  <div class="wrapper">
    <header>
      <div class="container">
        <router-link :to="{ name: 'empleabilidad'}" style="cursor:pointer; border: unset">
          <img loading="lazy" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/logo.png" alt="Aicad Business School" class="logo" itemprop="logo" tab="Aicad Business School">
        </router-link>
        <img alt="iconmenu" loading="lazy" class="icon-menu" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/icon-menu.png" @click="toggleMenu" />
        <Menu :isNavOpen="isNavOpen" @toggleMenu="toggleMenu" />
      </div>
    </header>
    <div class="lang">
      <ul class="idiomas">
        <li><a v-bind:class="[language == 'es' ? 'activeLang' : '']" name="es" @click="changeLanguage('es')" class="langText">Español</a></li>
        /
        <li><a v-bind:class="[language == 'en' ? 'activeLang' : '']" name="en" @click="changeLanguage('en')" class="langText">English</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
import Menu from '../../components/team/Menu';

export default {
  components: {
    Menu
  },
  data() {
    return {
      language: '',
      isNavOpen: false,
    }
  },
  methods: {
    changeLanguage(language) {
      this.$i18n.locale = language; 
      this.language = language;      
    },
    toggleMenu() {
      this.isNavOpen = !this.isNavOpen;
    },
  },
  mounted() {
    this.language = this.$i18n.locale;
  }
}
</script>

<style scoped lang="scss">
header {
  text-align: left;
  background: url(https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/pageheads/team-header.jpg) no-repeat top center;
  height: 250px;
}

.logo {
  width: 150px;
  margin: 50px 0 0;
}

.lang {
  position: relative;
  bottom: 0px;
  margin-bottom: -55px;
  background: transparent;
}

.idiomas {
  padding: 10px 0 20px;
  margin-bottom: 0;
}

.langText {
  cursor: pointer;
}

.idiomas {
  color: black;
}

.idiomas li {
  display: inline-block;
}

.idiomas li a:hover {
  /* color: #e1c9ca !important; */
  text-decoration: underline;
}

.activeLang {
  font-weight: 600;
}

.container {
  display: flex;
  justify-content: space-between;
}

.menu-bars {
  margin: auto 0;
  font-size: 2rem;
  cursor: pointer;
  opacity: 0.8;
  text-align: center;
  line-height: 1.5rem;
}

.menu-bars p {
  margin: 0;
  font-size: 1rem;
}

.menu-bars:hover {
  opacity: 1;
}


// /* Nav styles */
// .nav {
//   position: fixed;
//   top: 0;
//   right: 0;
//   height: 100vh;
//   width: 500px;
//   background: #242424;
//   z-index: 2;
//   transform: translateX(100%);
//   transition: 0.4s ease-in;
//   color: white;
//   padding-top: 15vh;
// }

// .nav.nav-open {
//   transform: translateX(0%);
// }

// .nav li {
//   text-transform: uppercase;
//   border-bottom: 1px solid #646363;
//   width: 100%;
//   margin-bottom: 15px;
//   cursor: pointer;
// }

// .nav li:hover {
//   color: #646363;
// }

// .nav ul.main-menu {
//   list-style-type: none;
//   width: 90%;
// }

// .nav .close {
//   color: white;
//   position: absolute;
//   top: 50px;
//   right: 50px;
// }

// ul.dropdown {
//   list-style-type: none;
//   margin-bottom: 30px;

//   li {
//     border-bottom: none;
//     text-transform: none;
//   }
// }

// .icon-down {
//   float: right;
// }

// @media screen and (max-width: 767px) {
//   .nav {
//     width: 100%;
//   }
// }

.icon-menu {
  width: auto;
  height: 60px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 23%;
  z-index: 9;
}

.icon-menu:hover {
  opacity: 0.8;
}
</style>