<template>
  <div class="header" id="topHead">
    <a href="/">
     <img
      loading="lazy" 
      class="logo"
      alt="logo"
      src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/logo.png"
     />
    </a>
    <div class="locale-changer">
      <span
        v-for="(lang, i) in langs"
        :key="`Lang${i}`"
        @click="changeLanguage(lang.code)"
        style="margin-right: 10px; cursor: pointer;"
        :class="$i18n.locale === lang.code ? 'active' : ''"
      >
        {{ lang.title }}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      langs: [
        {
          code: "en",
          title: "Eng"
        },
        {
          code: "es",
          title: "Esp"
        }
      ]
    };
  },
  methods: {
    changeLanguage(language) {
      this.$i18n.locale = language;
      this.language = language;
    }
  },
  mounted() {
    this.language = this.$i18n.locale;
  }
};
</script>
<style scoped lang="scss">
.header {
  position: sticky;
  top: 0;
  width: 100%;
  background: #009090;
  z-index: 9;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media screen and (max-width: 567px) {
    .locale-changer,
    .logo {
      margin-left: auto !important;
      margin-right: auto !important;
    }

    .logo {
      height: 48px;
    }

    & {
      padding-bottom: 10px;
    }
  }
  .logo {
    width: 350px;
    padding: 7px 50px;
    cursor: pointer;
    height: 53px;
  }

  .locale-changer {
    margin: auto 50px;
    font-size: 1.4rem;
    color: #fff;

    .active {
      font-weight: 600;
      text-decoration: underline;
    }
  }
}
</style>
