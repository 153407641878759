<template>
    <div class="header" id="topHead">
        <div class="blue-header">
          <div class="container">
            <div class="row">
              <div class="col-12 col-sm-8 col-md-8 col-xl-7 menu-header">
                <ul class="inicio">
                  <li><a href="https://www.aicad.es" target="_blank">aicad.es</a></li>
                  <li><router-link to="/blog">Blogs & Podcasts</router-link></li>
                  <li><router-link to="/socios">{{ $t('message.partners') }}</router-link></li>
                  <li><router-link to="/business">{{ $t('message.business') }}</router-link></li>
                  <li><router-link to="/personal">{{ $t('message.personal') }}</router-link></li>
                  <li><a href="https://campus.aicad.es/" target="_blank">{{ $t('message.virtual_campus') }}</a></li>
                </ul>
              </div> 
                
              <div class="col-4 col-sm-3 col-md-2 col-xl-1 menu-header">
                <ul class="idiomas">
                  <li><a v-bind:class="[language == 'es' ? 'activeLang' : '']" name="es" @click="changeLanguage('es')" class="langText">Es</a></li>
                  <li><a v-bind:class="[language == 'en' ? 'activeLang' : '']" name="en" @click="changeLanguage('en')" class="langText">En</a></li>
                </ul>
              </div>
              <div class="col-7 col-sm-5 col-md-3 col-xl-2 menu-header">
                <div class="buscar">
                  <form method="get" action="#" id="form_buscar" accept-charset="utf-8">
                    <input type="text" class="buscar" name="q">
                    <input type="hidden" name="lang" value="E">
                  </form>
                </div>
              </div>
              <div class="col-12 col-sm-4 col-md-3 col-xl-2 menu-header">
                <div class="contacto">
                  <div class="cont">
                    <router-link :to="{ path: '/#formulario'}" v-smooth-scroll="{ duration: 1500, offset: -100}">
                      <img loading="lazy" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/icono-telefono3.jpg" alt="Contacto">
                      <span>{{ $t('message.contact') }}</span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="nav_principal" class="nuevo-menus">
          <div class="container">
            <div class="row margin-row-right">
              <div class="col-12 col-sm-5 col-md-4 col-xl-3 menuPrinc">
                <div itemscope="" itemtype="https://schema.org/Organization" class="logoAicad">
                  <router-link to="/emprendedores" style="cursor:pointer; border: unset">
                    <img loading="lazy" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/logo.png" alt="Club de Ejecutivos" class="logo aicad" itemprop="logo" tab="Club de Ejecutivos">
                  </router-link>
                </div>
              </div>
              <div class="col-12 col-sm-7 col-md-8 col-xl-9 menuPrinc">
                <div class="menu-bars" @click="toggleMenu">
                    <font-awesome-icon :icon="['fas', 'bars']" />
                    <p>MENU</p>
                </div>
                <div class="nav" :class="[isNavOpen ? 'nav-open' : '']">
                    <font-awesome-icon :icon="['fas', 'times']" class="menu-bars close" @click="toggleMenu"/>
                    <ul class="main-menu">
                      <li @click="menuClicked('home')">
                        {{ $t('message.home') }}
                      </li>
                      <li @click="menuClicked('asesoramiento')">
                        {{ $t('emprendedores.menu.counseling') }}
                      </li>
                      <li @click="menuClicked('incubadora')">
                        {{ $t('emprendedores.menu.incubator') }}
                      </li>
                      <li @click="menuClicked('financion')">
                        {{ $t('emprendedores.financing') }}
                      </li>
                      <li @click="menuClicked('eprendemos-contigo')">
                        {{ $t('emprendedores.menu.we_understand') }}
                      </li>
                      <li @click="menuClicked('equipo')">
                        {{ $t('emprendedores.our_team') }}
                      </li>
                      <li @click="menuClicked('aceleracion')">
                        {{ $t('emprendedores.project-acceleration') }}
                      </li>
                      <li @click="menuClicked('recursos')">
                        {{ $t('emprendedores.menu.resources') }}
                      </li>
                      <li @click="menuClicked('colaboradores')">
                        {{ $t('emprendedores.collaborators') }}
                      </li>
                    </ul>
                    </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
      language: '',
      isNavOpen: false,
    }
  },
  methods: {
    changeLanguage(language) {
      this.$i18n.locale = language; 
      this.language = language;      
    },
    toggleMenu() {
      this.isNavOpen = !this.isNavOpen;
    },
    menuClicked(itemName) {
        if(itemName === 'home') 
            this.$router.push({ path: '/emprendedores/'});
        else if(itemName === 'asesoramiento') 
            this.$router.push({ path: '/emprendedores/asesoramiento'});
        else if(itemName === 'incubadora')
            this.$router.push({ path: '/emprendedores/incubadora'});
        else if(itemName === 'financion') 
            this.$router.push({ path: '/emprendedores/financiacion'});
        else if(itemName === 'eprendemos-contigo')
            this.$router.push({ path: '/emprendedores/emprendemos-contigo'});
        else if(itemName === 'equipo')
            this.$router.push({ path: '/emprendedores/equipo'});
        else if(itemName === 'aceleracion')
            this.$router.push({ path: '/emprendedores/programa-aceleracion-proyectos'});
        else if(itemName === 'recursos')
            this.$router.push({ path: '/emprendedores/recursos'});
        else if(itemName === 'colaboradores')
            this.$router.push({ path: '/emprendedores/colaboradores'});

        this.toggleMenu();
      }
  },
  mounted() {
    this.language = this.$i18n.locale;
  }
}
</script>

<style scoped lang="scss">
.topHead {
    text-align: left;
}

img.logo.aicad {
    height: 74px;
}

/* My styles */
.blue-header {
    background: #414552;
    width: 100%;
    float: left;
    height: auto;
}

.container {
    max-width: 1170px;
    height: 38px;
    padding-left: 0;
    padding-right: 0;
    margin-right: auto;
    margin-left: auto;
}

.row {
    margin-right: -15px;
    margin-left: -15px;
}

.blue-header ul.inicio {
    float: left;
    height: auto;
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 6px;
}

.idiomas {
    padding: 0px;
    float: left;
    height: auto;
    margin-bottom: 0;
    margin-top: 6px;
    display: flex;
}

.buscar {
    float: left;
    height: 22px;
    margin-top: 2px;
    margin-bottom: 4px;
}

input.buscar {
  width: 100% !important;
}

.contacto {
    width: auto;
    float: left;
    height: auto;
    margin-top: 2px;
}

.blue-header ul.inicio li {
    width: auto;
    float: left;
    height: auto;
    list-style: none;
    padding: 0 10px;
    border-right: 1px solid white;
}

.idiomas li {
    width: auto;
    float: left;
    height: auto;
    list-style: none;
    padding: 0 10px;
    border-right: 1px solid white;
}

input.buscar {
    background: #ffffff url("https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/icons/lupa2.png") no-repeat 0.7em center;
    border: 0;
    color: #333;
    width: 75%;
    padding: 1px 0.2em 1px 2.5em;
}

.contacto a {
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    color: #ffffff;
}

.contacto span {
    margin-left: 5px;
}

body {
  margin: 0px;
}

.blue-header ul.inicio li a {
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
    color: #ffffff;
    text-decoration: none;
}

.blue-header ul.inicio li a:hover {
  color: #e1c9ca;
}

.idiomas li a, .contacto a {
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    color: #ffffff;
    text-decoration: none;
    
}

.contacto a:hover  {
  color: #e1c9ca;
  text-decoration: none;
}

.langText {
  cursor: pointer;
}

.idiomas {
  color: white;
}

.idiomas li a:hover {
    color: #e1c9ca !important;
}

.blue-header .container {
  height: auto;
  padding-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .wrapper {
    overflow-x: hidden;
  }
  .header {
    margin-bottom: 0px;
  }
  
}

.activeLang {
  font-weight: 600;
}

.cont, .buscar {
  margin-left: 10px;
}

.menu-header {
  padding-top: 3px;
  /* padding-left: 0px !important; */
  padding-right: 0px !important;
}

.header {
  width: 100%;
  float: left;
  height: auto;
  margin-bottom: 2rem;
}

.nuevo-menus {
  width: 100%;
  float: left;
  height: auto;
  padding: 40px 0 10px;
}

.nuevo-menus .container {
    height: auto;
}

.logoAicad {
    width: 100%;
    float: left;
    height: auto;
    /* padding-right: 20px; */
}

img.logo.aicad {
    transition: 0.3s ease;
}
img.logo.aicad:hover {
    /* opacity: 0.6; */
    transform: scale(1.1);
    transition: 0.3s ease;
}

.menu-bars {
  margin: auto 0;
  font-size: 2rem;
  cursor: pointer;
  opacity: 0.8;
  line-height: 1.5rem;
  text-align: right;
}

.menu-bars p {
  margin: 0;
  font-size: 1rem;
}

.menu-bars:hover {
  opacity: 1;
}


/* Nav styles */
.nav {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 500px;
  background: #242424;
  z-index: 2;
  transform: translateX(100%);
  transition: 0.4s ease-in;
  color: white;
  padding-top: 15vh;
  text-align: left;
}

.nav.nav-open {
  transform: translateX(0%);
}

.nav li {
  text-transform: uppercase;
  border-bottom: 1px solid #646363;
  width: 100%;
  margin-bottom: 15px;
  cursor: pointer;
}

.nav li:hover {
  color: #646363;
}

.nav ul.main-menu {
  list-style-type: none;
  width: 90%;
}

.nav .close {
  color: white;
  position: absolute;
  top: 50px;
  right: 50px;
  height: 50px;
  width: 50px;
}

ul.dropdown {
  list-style-type: none;
  margin-bottom: 30px;

  li {
    border-bottom: none;
    text-transform: none;
  }
}

.icon-down {
  float: right;
}

@media screen and (max-width: 767px) {
  .menu-bars {
    text-align: center;
    padding-top: 25px;
  }

  .nav {
    width: 100%;
  }
}
</style>