<template>
    <div class="nav" :class="[isNavOpen ? 'nav-open' : '']">
        <font-awesome-icon :icon="['fas', 'times']" class="menu-bars close" @click="$emit('toggleMenu')"/>
        <ul class="main-menu">
        <li @click="menuClicked('home')">
          {{ $t('message.home') }}
        </li>
        <li v-b-toggle.menu-accordion-1>
            {{ $t('empleabilidad.menu.employment_portal') }}
            <font-awesome-icon :icon="['fas', 'chevron-down']" class="icon-down"/>
        </li>
        <b-collapse id="menu-accordion-1" accordion="my-accordion" role="tabpanel">
            <ul class="dropdown">
            <li @click="menuClicked('area_empresas')">
              {{ $t('empleabilidad.business_area') }}
            </li>
            <li @click="menuClicked('area_candidatos')">
              {{ $t('empleabilidad.candidates_area') }}
            </li>
            </ul>
        </b-collapse>
        <li v-b-toggle.menu-accordion-2>
            {{ $t('empleabilidad.counseling') }}
            <font-awesome-icon :icon="['fas', 'chevron-down']" class="icon-down"/>
        </li>
        <b-collapse id="menu-accordion-2" accordion="my-accordion" role="tabpanel">
            <ul class="dropdown">
            <li @click="menuClicked('asesoramiento')">
              {{ $t('empleabilidad.counseling') }}
            </li>
            <li @click="menuClicked('recursos_empleo')">
              {{ $t('empleabilidad.menu.employment_resourses') }}
            </li>
            </ul>
        </b-collapse>
        <li @click="menuClicked('networking')">
          {{ $t('empleabilidad.menu.club_and_networking') }}
        </li>
        <li @click="menuClicked('team')">
          {{ $t('empleabilidad.our_team') }}
        </li>
        <li @click="menuClicked('club_emprendedores')">
          {{ $t('empleabilidad.entrepreneurs_club') }}
        </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: ['isNavOpen'],
    methods: {
      menuClicked(itemName) {
        if(itemName === 'home') 
            this.$router.push({ path: '/empleabilidad/'});
        else if(itemName === 'area_empresas') 
            this.$router.push({ path: '/empleabilidad/area_empresas'});
        else if(itemName === 'area_candidatos')
            this.$router.push({ path: '/empleabilidad/area_candidatos/practicas'});
        else if(itemName === 'asesoramiento') 
            this.$router.push({ path: '/empleabilidad/asesoramiento', query: { menu: 'asesoramiento' }});
        else if(itemName === 'recursos_empleo')
            this.$router.push({ path: '/empleabilidad/asesoramiento', query: { menu: 'recursos' }});
        else if(itemName === 'networking')
            this.$router.push({ path: '/empleabilidad/networking'});
        else if(itemName === 'team')
            this.$router.push({ path: '/empleabilidad/equipo'});
        else if(itemName === 'club_emprendedores')
            this.$router.push({ path: '/club/emprendedores'});

        this.$emit('toggleMenu');
      }
    }
}
</script>

<style scoped lang="scss">
/* Nav styles */
.nav {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 500px;
  background: #242424;
  z-index: 2;
  transform: translateX(100%);
  transition: 0.4s ease-in;
  color: white;
  padding-top: 15vh;
}

.nav.nav-open {
  transform: translateX(0%);
  text-align: left;
}

.nav li {
  text-transform: uppercase;
  border-bottom: 1px solid #646363;
  width: 100%;
  margin-bottom: 15px;
  cursor: pointer;
}

.nav li:hover {
  color: #646363;
}

.nav ul.main-menu {
  list-style-type: none;
  width: 90%;
}

.nav .close {
  color: white;
  position: absolute;
  top: 50px;
  right: 50px;
  cursor: pointer;
}

ul.dropdown {
  list-style-type: none;
  margin-bottom: 30px;

  li {
    border-bottom: none;
    text-transform: none;
  }
}

.icon-down {
  float: right;
}

@media screen and (max-width: 767px) {
  .nav {
    width: 100%;
  }
}
</style>