import Vue from 'vue'

import App from './App.vue'
import router from './router'
import store from './store/store'
import axios from 'axios';
import VueAxios from 'vue-axios';

import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import VueI18n from 'vue-i18n'
import messages from './lang/messages.json'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faAngleDoubleUp, faPhone, faUserGraduate, faChalkboardTeacher,
  faUserTie, faCity, faEdit, faTrash, faUserCheck, faHandshake, faUsers,
  faCheck, faBars, faTimes, faAngleDoubleRight, faUnlock, faCaretDown,
  faFileAlt, faChevronDown, faBook, faSuitcase, faChartLine, faAward,
  faUsersCog, faDownload, faLightbulb, faArrowRight, faUser, faSitemap,
  faCertificate, faEuroSign, faCommentDots, faFileDownload, faClock,
  faFilePdf, faPlus, faEye, faFileCode } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faFacebook, faLinkedin, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import VueCarousel from 'vue-carousel'
import VueSilentbox from 'vue-silentbox'
import vueSmoothScroll from 'vue2-smooth-scroll'
import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'

import Vuelidate from 'vuelidate'

import Empty from './layouts/Empty.vue';
import Default from './layouts/Default.vue';
import Team from './layouts/Team.vue';
import Entrepreneurs from './layouts/Entrepreneurs.vue';
import Spain from './layouts/Spain.vue';

// Vue scroll to
var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo, {
  container: "body",
  duration: 1500,
  easing: "ease",
  offset: -150,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
});
// 

Vue.component('empty-layout', Empty);
Vue.component('default-layout', Default);
Vue.component('team-layout', Team);
Vue.component('entrepreneurs-layout', Entrepreneurs);
Vue.component('spain-layout', Spain);

library.add(faTwitter, faFacebook, faLinkedin, faYoutube, faInstagram, faAngleDoubleUp,
  faPhone, faUserGraduate, faChalkboardTeacher, faUserTie, faCity, faEdit,
  faTrash, faUserCheck, faHandshake, faUsers, faCheck, faBars, faTimes,
  faAngleDoubleRight, faUnlock, faCaretDown, faFileAlt, faChevronDown, faBook,
  faSuitcase, faChartLine, faAward, faUsersCog, faDownload, faLightbulb,
  faArrowRight, faUser, faSitemap, faCertificate, faEuroSign, faCommentDots,
  faFileDownload, faClock, faFilePdf, faPlus, faEye, faFileCode)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(BootstrapVue)
Vue.use(VueI18n)
Vue.use(VueCarousel)
Vue.use(VueSilentbox)
Vue.use(Vuelidate)
Vue.use(vueSmoothScroll)
Vue.use(VueVideoPlayer)
// Vue.use(CKEditor);
Vue.use(VueAxios, axios);

Vue.router = router;

process.env.NODE_ENV == 'development' ? 
  Vue.axios.defaults.baseURL = 'https://clubejecutivo.aicad.es/' :
  Vue.axios.defaults.baseURL = '/api'

process.env.NODE_ENV == 'production' ? 
  Vue.axios.defaults.baseURL = 'https://clubejecutivo.aicad.es/' :
  Vue.axios.defaults.baseURL = '/api'


if(window.__PRERENDER_INJECTED) {
  if(window.__PRERENDER_INJECTED.isPrerender === true) {
    Vue.axios.defaults.baseURL = 'https://clubejecutivo.aicad.es/' //for prerendering
  }
}

// Vue.use(require('@websanova/vue-auth'), {
//   auth: require('@websanova/vue-auth/drivers/auth/bearer.js'),
//   http: require('@websanova/vue-auth/drivers/http/axios.1.x.js'),
//   router: require('@websanova/vue-auth/drivers/router/vue-router.2.x.js')
// })

Vue.config.productionTip = false

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'es', // set locale
  fallbackLocale: 'en',
  messages, // set locale messages
})


const app = new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
});

if (window.__INITIAL_STATE__) store.replaceState(window.__INITIAL_STATE__);

router.beforeResolve(async (to, from, next) => {
  try {    
    const components = router.getMatchedComponents(to);

    // By using `await` we make sure to wait
    // for the API request made by the `fetch()`
    // method to resolve before rendering the view.
    await Promise.all(components.map(x => x.fetch && x.fetch({ store })));

    // The `injectInitialState()` function injects
    // the current state as a global variable
    // `__INITIAL_STATE__` if the page is currently
    // pre-rendered.
    if (window.__PRERENDER_INJECTED) injectInitialState(store.state);
  } catch (error) {
    // This is the place for error handling in
    // case the API request fails for example.
    console.log(error);
  }
  return next();
});


document.addEventListener('DOMContentLoaded', function () {
  app.$mount('#app');
})
