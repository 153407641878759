<template>
  <div class="wrapper">
    <Header/>
        <!-- <div v-if="$auth.ready()"> -->
          <slot />
        <!-- </div>
        <div v-if="!$auth.ready()">
          Loading ...
        </div> -->
    <Footer/>
  </div>
</template>

<script>
import Header from '../components/Header.vue';
import Footer from '../components/Footer.vue';

export default {
  name: 'Default',
  components: {
    Header,
    Footer
  }
}
</script>