<template>
	<div class="wrapper">
		<div id="app">
			<div style="background: #4e4e4e;">
				<Map />
			</div>
			<div class="footer">
				<svg
					class="kampas-top hidden-xs"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 100 100"
					preserveAspectRatio="none"
					style="background-color: #4e4e4e; height: 100px;width: 100%;"
				>
				</svg>
				<polygon fill="white" points="0,100 100,0 0,0"></polygon>
				<div class="mainFooter">
					<div class="container">
						<div class="col-6 col-lg-4 footThreeCols oficinas">
							<div>
								<div class="d-flex">
									<font-awesome-icon :icon="['fas', 'building']" style="font-size: 4rem; margin-top: 10px;" />
									<h2>{{ $t('footer.offices.title') }}</h2>
								</div>
								<div class="col-6 col-lg-6" style="display:inline-block;">
									<ul class="officinas">
										<li>
											<a target="_blank" href="https://www.aicad.es/alicante/">
												Alicante
											</a>
										</li>
										<li>
											<a target="_blank" href="https://www.aicad.es/barcelona/">
												Barcelona
											</a>
										</li>
										<li>
											<a target="_blank" href="https://www.aicad.es/coruna/">
												Coruña
											</a>
										</li>
										<li>
											<a target="_blank" href="https://www.aicad.es/granada/">
												Granada
											</a>
										</li>
										<li>
											<a target="_blank" href="https://www.aicad.es/larioja/">
												La Rioja
											</a>
										</li>
										<li>
											<a target="_blank" href="https://www.aicad.es/lugones/">
												Lugones-Asturias
											</a>
										</li>
										<li>
											<a target="_blank" href="https://www.aicad.es/malaga/">
												Málaga
											</a>
										</li>
										<li>
											<a target="_blank" href="https://www.aicad.es/valladolid/">
												Valladolid
											</a>
										</li>
									</ul>
								</div>
								<div class="col-6 col-lg-6" style="display:inline-block;">
									<ul class="officinas">
										<li>
											<a target="_blank" href="https://www.aicadbilbao.com/">
												Bilbao
											</a>
										</li>
										<li>
											<a target="_blank" href="https://www.aicad.es/castellon/">
												Castellón
											</a>
										</li>
										<li>
											<a target="_blank" href="http://www.aicad-madrid.es/">
												Juan-Bravo
											</a>
										</li>
										<li>
											<a target="_blank" href="https://www.aicad.es/madrid/">
												Madrid
											</a>
										</li>
										<li>
											<a target="_blank" href="https://www.aicad.es/tenerife/">
												Tenerife
											</a>
										</li>
										<li>
											<a target="_blank" href="https://www.aicad.es/cuenca/">
												Ecuador
											</a>
										</li>
										<li>
											<a target="_blank" href="http://www.aicad.do/">
												{{ $t('footer.offices.dominican_rep') }}
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="col-lg-4 footThreeCols noSmallCall">
						</div>
						<div class="col-10 col-sm-6 col-md-6 col-lg-4 footThreeCols contacts" style="text-align: left; vertical-align: top;">
							<div class="footerCols" id="contactCol" style="margin-right: 0px;">
								<div>
									<a target="_blank" rel="nofollow" href="https://twitter.com/abusinessschool">
										<font-awesome-icon :icon="['fab', 'twitter']" class="socialIcon" />
									</a>
									<a target="_blank" rel="nofollow" href="https://www.facebook.com/AICADBUSINESSSCHOOL/">
										<font-awesome-icon :icon="['fab', 'facebook']" class="socialIcon" />
									</a>
									<a target="_blank" rel="nofollow" href="https://es.linkedin.com/school/aicad-business-school/">
										<font-awesome-icon :icon="['fab', 'linkedin']" class="socialIcon" />
									</a>
									<a target="_blank" rel="nofollow" href="https://www.youtube.com/channel/UC1AZ0pvMZUCl6ete8j6KTZw">
										<font-awesome-icon :icon="['fab', 'youtube']" class="socialIcon" />
									</a>
									<a target="_blank" rel="nofollow" href="https://www.instagram.com/aicad/">
										<font-awesome-icon :icon="['fab', 'instagram']" class="socialIcon" />
									</a>
								</div>
								<div class="phoneDiv">
									<a href="tel:8496242332" class="footerNumber">
										+49 15 77 33 44 100
									</a>
									<span>{{ $t('footer.offices.germany') }}</span>
								</div>
								<div class="phoneDiv">
									<a href="tel:8496242332" class="footerNumber">
										+593 98 306 2916
									</a>
									<span>ECUADOR</span>
								</div>
								<div class="phoneDiv">
									<a href="tel:8496242332" class="footerNumber">
										+502 33154792
									</a>
									<span>GUATEMALA</span>
								</div>
								<div class="phoneDiv">
									<a href="tel:8496242332" class="footerNumber">
										+39 02 5656 9394
									</a>
									<span>{{ $t('footer.offices.italy') }}</span>
								</div>
								<div class="phoneDiv">
									<a href="tel:+5218112593252" class="footerNumber">
										+5218112593552
									</a>
									<span>{{ $t('footer.offices.mexico') }}</span>
								</div>
								<div class="phoneDiv">
									<a href="tel:8496242332" class="footerNumber">849 624 2332</a>
									<span>{{ $t('footer.offices.republica_dominicana') }}</span>
								</div>
								<div class="btnEnviar formPie"></div>
							</div>
						</div>
					</div>
				</div>
				<svg
					class="kampas"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 100 100"
					preserveAspectRatio="none"
					style="background-color: #f3f3f3;margin-bottom:20px; height: 100px;width: 100%;transform: scale(1, -1);"
				>
					<polygon fill="white" points="0,100 100,0 0,0"></polygon>
				</svg>
				<a class="angleUp" v-scroll-to="'#app'">
					<font-awesome-icon class="doubleUpIcon" :icon="['fas', 'angle-double-up']" />
				</a>
				<div class="subFooter">
					<div class="beforeFooterEnd" style="    margin-bottom: 30px;">
						<div style="width:60%; margin:auto;">
							<div class="twoCols">
								<div class="title-effect">
									<span class="first" style="display: block;text-transform: uppercase;color: #333; font-size:36px;line-height: 25px;"
										>Get</span
									>
									<div
										class="rw-words rw-words-1"
										style="letter-spacing: normal;color: #333;text-transform: uppercase;font-weight: 700;line-height: 44px;font-size:48px;display: inline;"
									>
										<span style="position: absolute; animation-delay: 0s">
											more
										</span>
										<span style="position: absolute; animation-delay: 2s">
											the best
										</span>
										<span style="position: absolute; animation-delay: 4s">
											the best
										</span>
									</div>
									<hr style="margin-top: 25px;visibility: hidden;margin-bottom: 0px;" />
									<span
										class="title-effect-sub"
										style="line-height: 2.4em;font-size: 22px;text-transform: lowercase;color: #666;font-weight: 400;white-space: nowrap;"
										>of human talent</span
									>
								</div>
							</div>
							<div class="twoCols" style="text-align: right;">
								<img alt="footerlogos" loading="lazy" width="93" height="66" src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/footLogos.png" />
							</div>
						</div>
					</div>
					<div class="footerEnd">
						<div style="width: 60%;margin: auto;display:flex;flex-wrap:wrap;justify-content: space-between;">
							<div class="hidden-xs">
								<a target="_blank" href="https://www.aicad.es/aviso-legal-privacidad/">
									{{ $t('footer.footer.privacy_policy') }}
								</a>
								<a target="_blank" href="https://www.aicad.es/delegaciones/">
									{{ $t('footer.footer.delegations') }}
								</a>
								<a target="_blank" href="https://www.aicad.es/condiciones-de-contratacion/">
									{{ $t('footer.footer.general_conditions') }}
								</a>
								<a target="_blank" href="https://www.aicad.es/politica-de-cookies/">
									{{ $t('footer.footer.cookies_policy') }}
								</a>
							</div>
							<router-link :to="{ name: 'admin' }">Login</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Map from './Map';
export default {
	components: {
		Map,
	},
};
</script>
<style scoped>
#app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	overflow-x: hidden;
}
.ck.ck-content.ck-editor__editable {
	min-height: 250px;
	text-align: left;
}
.contentp h2,
.contentp h3 {
	display: none;
}
.rowas {
	width: 100%;
}
.container {
	height: auto;
	margin-left: auto;
	margin-right: auto;
}
.title p {
	font-weight: 300;
	font-size: 34px;
	margin: 10px;
	padding: 10px 0;
	color: #232323;
	text-transform: uppercase;
	font-family: 'open sans condensed', Arial, sans-serif;
	text-align: left;
	letter-spacing: -4px;
}

.title p span {
	font-weight: 700;
	color: #0070aa;
	margin-top: -12px;
}

figure.image img {
	max-width: 100%;
}

figcaption {
	font-size: 12pt;
	color: #696969;
}

figure.image.image-style-side {
	float: right;
	margin: 0px 10px;
}

.content p figure div {
	width: 100%;
}

.routerLink:hover {
	text-decoration: none;
}

.footerEnd {
	line-height: 60px;
	background: #f2f2f2;
	text-align: center;
}

.footerEnd a {
	color: #666;
	font-size: 13px;
	padding: 0 15px 0 0;
}

.subFooter .twoCols {
	display: inline-block;
	width: 49%;
}

.beforeFooterEnd .title-effect .rw-words-1 span {
	animation-name: rotateWordsFirst;
	animation-duration: 6s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	animation-delay: 0s;
	opacity: 0;
	margin-left: -4px;
}

/* Change words in botoom footer influence message */
@keyframes rotateWordsFirst {
	0% {
		opacity: 0;
		animation-timing-function: linear;
		height: 0;
	}
	8% {
		opacity: 1;
		/* height: 60px; */
	}
	19% {
		opacity: 1;
		/* height: 60px; */
	}
	40% {
		opacity: 0;
		/* height: 60px; */
	}
	100% {
		opacity: 0;
	}
}

.title-effect {
	text-align: left;
}

@media screen and (max-width: 767px) {
	.twoCols img {
		visibility: hidden;
	}
}

.mainFooter {
   background: #f3f3f3 url(https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/footerImage.png) no-repeat ;
   background-size: 25%;
   background-position: center;
	width: inherit;
	position: relative;
	padding-top: 25px;
}

.socialIcon {
	width: 45px;
	height: 45px;
	border-radius: 50%;
	padding: 12px;
	border: 2px solid black;
	color: black;
}

.footerCols a {
	margin-right: 5px;
	line-height: 7px;
}

.footerNumber {
	display: block;
	font-weight: 700;
	color: #000000b5;
	letter-spacing: 1px;
	margin-top: 15px;
	font-size: 1.6rem;
	line-height: 1.5rem !important;
}

.phoneDiv {
	margin-bottom: 15px;
}

.footerCols span {
	color: black;
	font-size: 12px;
	letter-spacing: 1px;
}

.footThreeCols {
	display: inline-block;
	margin-top: 30px;
	text-align: right;
}

ul.officinas {
	list-style: none;
}

.officinas a {
	color: black;
	/* font-size: 15px; */
	font-size: 1.7rem;
}

.angleUp {
	position: relative;
	float: right;
	margin-right: 18%;
	margin-top: -60px;
	cursor: pointer;
}

.angleUp .socialIcon {
	color: white;
	border-color: white;
	background: black;
}

.btnEnviar a {
	border: 3px solid black;
	float: left;
	width: 100%;
	padding: 11px 10px;
	text-align: left;
	color: black;
	text-decoration: none;
}

.btnEnviar {
	width: 45%;
	color: black;
	font-size: 16px;
	background: transparent;
	margin-bottom: 6px;
	float: left;
}

.formPie {
	width: 30%;
	margin-top: 10px;
	margin-right: 12px;
}

@media screen and (max-width: 991px) {
	.noSmallCall {
		display: none;
	}

	.mainFooter {
		background: #f3f3f3;
	}

	.cm-footer__map-container {
		display: none;
	}
}

@media screen and (max-width: 575px) {
	.oficinas {
		display: none;
	}

	.contacts {
		text-align: center !important;
	}

	.formPie {
		width: 50%;
		margin-right: auto;
		margin-left: auto;
	}

	.formPie a {
		text-align: center;
	}
}

.oficinas h2 {
	font-weight: 300;
	font-size: 34px;
	margin: 10px;
	color: #232323;
	font-family: 'open sans condensed', Arial, sans-serif;
	text-transform: uppercase;
	letter-spacing: -2px;
	text-align: left;
}

svg.socialIcon:hover {
	border-color: #333;
	transition: 0.3s ease;
}

svg.socialIcon {
	transition: 0.3s ease;
}

svg.socialIcon.svg-inline--fa.fa-youtube.fa-w-18:hover {
	color: red;
}

svg.socialIcon.svg-inline--fa.fa-facebook.fa-w-14:hover {
	color: #3c5a99;
}

svg.socialIcon.svg-inline--fa.fa-twitter.fa-w-16:hover {
	color: #1bb2e9;
}

svg.socialIcon.svg-inline--fa.fa-linkedin.fa-w-14:hover {
	color: #006699;
}

a.footerNumber {
	text-decoration: none;
	transition: 0.3s ease;
}

a.footerNumber:hover {
	opacity: 0.6;
	transition: 0.3s ease;
}

.btnEnviar a:hover {
	transform: scale(1.05);
	transition: 0.3s ease;
	color: black !important;
}

.btnEnviar a {
	transition: 0.3s ease;
}

.doubleUpIcon {
	border-radius: 50%;
	padding: 12px;
	width: 40px;
	height: 40px;
	color: white;
	background: black;
}

svg.doubleUpIcon:hover {
	opacity: 0.8;
	transition: 0.3s ease;
}

svg.doubleUpIcon {
	transition: 0.3s ease;
}

.officinas a:hover {
	opacity: 0.8;
	transition: 0.3s ease;
}

.officinas a {
	transition: 0.3s ease;
}

@media screen and (max-width: 1219px) {
  .formPie {
    width: 40%;
  }
}
</style>
