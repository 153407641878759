<template>
  <div class="wrapper">
    <Header />
    <!-- <div v-if="$auth.ready()"> -->
      <router-view/> 
    <!-- </div>
    <div v-if="!$auth.ready()">
      Loading ...
    </div> -->
    <Footer />
  </div>
</template>

<script>
import Header from '../components/team/Header.vue';
import Footer from '../components/Footer.vue';

export default {
  components: {
    Header,
    Footer
  }
}
</script>

<style>
  #app {
    font-family: 'Open sans', Arial, sans-serif;
  }
</style>