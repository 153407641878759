import Vue from 'vue'
import Router from 'vue-router'
import NProgress from 'nprogress';
import Meta from 'vue-meta';

Vue.use(Router)
Vue.use(Meta)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import(/* webpackChunkName: "home" */ './views/Home.vue')
    },
    {
      path: '/inovation',
      name: 'inovation',
      component: () => import(/* webpackChunkName: "inovation" */ './views/Inovation.vue')
    },
    {
      path: '/education',
      name: 'education',
      component: () => import(/* webpackChunkName: "education" */ './views/ExecutiveEducation.vue')
    },
    {
      path: '/socios',
      name: 'partners',
      component: () => import(/* webpackChunkName: "partners" */ './views/Partners.vue') 
    },
    {
      path: '/personal',
      name: 'personal',
      component: () => import(/* webpackChunkName: "personal" */ './views/Personal.vue') 
    },
    {
      path: '/business',
      name: 'business',
      component: () => import(/* webpackChunkName: "business" */ './views/Business.vue') 
    },
    {
      path: '/poll',
      name: 'poll',
      component: () => import(/* webpackChunkName: "poll" */ './views/Poll.vue') 
    },
    {
      path: '/blog',
      name: 'blog',
      component: () => import(/* webpackChunkName: "about" */ './views/Blog.vue') 
    },
    {
      path: '/blogpost/:id',
      name: 'blogpost',
      component: () => import(/* webpackChunkName: "blogpost" */ './views/BlogPost.vue') 
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import(/* webpackChunkName: "Login" */ './views/auth/Login.vue') 
    },
    {
      path: '/admin/dashboard',
      name: 'dashboard',
      meta: {
        auth: true,
      },
      component: () => import(/* webpackChunkName: "dashboard" */ './views/admin/Dashboard.vue')
    },
    {
      path: '/admin/blogs',
      name: 'adminblog',
      meta: {
        auth: true,
      },
      component: () => import(/* webpackChunkName: "adminblog" */ './views/admin/Blogs.vue')
    },
    {
      path: '/admin/blogs/new',
      name: 'newblog',
      meta: {
        auth: true,
      },
      component: () => import(/* webpackChunkName: "newblog" */ './views/admin/ManageBlog.vue')
    },
    {
      path: '/admin/blogs/edit/:id',
      name: 'editblog',
      props: true,
      meta: {
        auth: true,
      },
      component: () => import(/* webpackChunkName: "editblog" */ './views/admin/ManageBlog.vue')
    },
    {
      path: '/empleabilidad',
      name: 'empleabilidad',
      meta: {
        layout: 'empty'
      },
      component: () => import(/* webpackChunkName: "empleabilidad" */ './views/team/Home.vue')
    },
    {
      path: '/empleabilidad/equipo',
      name: 'equipo',
      meta: {
        layout: 'team'
      },
      component: () => import(/* webpackChunkName: "equipo" */ './views/team/Team.vue')
    },
    {
      path: '/empleabilidad/area_empresas',
      name: 'team',
      meta: {
        layout: 'team'
      },
      component: () => import(/* webpackChunkName: "team" */ './views/team/BusinessArea.vue')
    },
    {
      path: '/empleabilidad/area_candidatos/practicas',
      name: 'practicas',
      meta: {
        layout: 'team'
      },
      component: () => import(/* webpackChunkName: "practicas" */ './views/team/CandidatosPracticas.vue')
    },
    {
      path: '/empleabilidad/asesoramiento',
      name: 'asesoramiento',
      meta: {
        layout: 'team'
      },
      component: () => import(/* webpackChunkName: "asesoramiento" */ './views/team/Counseling.vue')
    },
    {
      path: '/empleabilidad/asesoramiento/article',
      name: 'article',
      meta: {
        layout: 'team'
      },
      component: () => import(/* webpackChunkName: "article" */ './components/team/resources/Article.vue')
    },
    {
      path: '/empleabilidad/asesoramiento/curriculum',
      name: 'curriculum',
      meta: {
        layout: 'team'
      },
      component: () => import(/* webpackChunkName: "curriculum" */ './components/team/resources/Curriculum.vue')
    },
    {
      path: '/empleabilidad/asesoramiento/talento',
      name: 'talento',
      meta: {
        layout: 'team'
      },
      component: () => import(/* webpackChunkName: "talento" */ './components/team/resources/Talento.vue')
    },
    {
      path: '/empleabilidad/asesoramiento/diversidad',
      name: 'diversidad',
      meta: {
        layout: 'team'
      },
      component: () => import(/* webpackChunkName: "diversidad" */ './components/team/resources/Diversidad.vue')
    },
    {
      path: '/empleabilidad/asesoramiento/bloglinks',
      name: 'bloglinks',
      meta: {
        layout: 'team'
      },
      component: () => import(/* webpackChunkName: "bloglinks" */ './components/team/resources/Bloglinks.vue')
    },
    {
      path: '/empleabilidad/asesoramiento/empresaslinks',
      name: 'empresaslinks',
      meta: {
        layout: 'team'
      },
      component: () => import(/* webpackChunkName: "empresaslinks" */ './components/team/resources/Empresaslinks.vue')
    },
    {
      path: '/empleabilidad/asesoramiento/legislacion',
      name: 'legislacion',
      meta: {
        layout: 'team'
      },
      component: () => import(/* webpackChunkName: "legislacion" */ './components/team/resources/Legislacionlinks.vue')
    },
    {
      path: '/empleabilidad/asesoramiento/portales',
      name: 'portales',
      meta: {
        layout: 'team'
      },
      component: () => import(/* webpackChunkName: "portales" */ './components/team/resources/Portaleslinks.vue')
    },
    {
      path: '/empleabilidad/asesoramiento/revistas',
      name: 'revistas',
      meta: {
        layout: 'team'
      },
      component: () => import(/* webpackChunkName: "revistas" */ './components/team/resources/Revistaslinks.vue')
    },
    {
      path: '/empleabilidad/networking',
      name: 'networking',
      meta: {
        layout: 'team'
      },
      component: () => import(/* webpackChunkName: "networking" */ './views/team/Networking.vue')
    },
    {
      path: '/club/emprendedores',
      name: 'club-emprendedores',
      meta: {
        layout: 'team'
      },
      component: () => import(/* webpackChunkName: "club-emprendedores" */ './views/team/ClubEmprendedores.vue')
    },
    {
      path: '/emprendedores',
      name: 'emprendedores',
      meta: {
        layout: 'entrepreneurs'
      },
      component: () => import(/* webpackChunkName: "emprendedores" */ './views/entrepreneurs/Home.vue')
    },
    {
      path: '/emprendedores/asesoramiento',
      name: 'emprendedores_asesoramiento',
      meta: {
        layout: 'entrepreneurs'
      },
      component: () => import(/* webpackChunkName: "emprendedores_asesoramiento" */ './views/entrepreneurs/Counseling.vue')
    },
    {
      path: '/emprendedores/incubadora',
      name: 'incubadora',
      meta: {
        layout: 'entrepreneurs'
      },
      component: () => import(/* webpackChunkName: "incubadora" */ './views/entrepreneurs/Incubator.vue')
    },
    {
      path: '/emprendedores/financiacion',
      name: 'financiacion',
      meta: {
        layout: 'entrepreneurs'
      },
      component: () => import(/* webpackChunkName: "financiacion" */ './views/entrepreneurs/Financing.vue')
    },
    {
      path: '/emprendedores/emprendemos-contigo',
      name: 'emprendemos-contigo',
      meta: {
        layout: 'entrepreneurs'
      },
      component: () => import(/* webpackChunkName: "emprendemos-contigo" */ './views/entrepreneurs/WeUnderstand.vue')
    },
    {
      path: '/emprendedores/equipo',
      name: 'emprendedores-equipo',
      meta: {
        layout: 'entrepreneurs'
      },
      component: () => import(/* webpackChunkName: "emprendedores-equipo" */ './views/entrepreneurs/OurTeam.vue')
    },
    {
      path: '/emprendedores/programa-aceleracion-proyectos',
      name: 'programa-aceleracion-proyectos',
      meta: {
        layout: 'entrepreneurs'
      },
      component: () => import(/* webpackChunkName: "programa-aceleracion-proyectos" */ './views/entrepreneurs/ProjectAcceleration.vue')
    },
    {
      path: '/emprendedores/recursos',
      name: 'programa-aceleracion-proyectos',
      meta: {
        layout: 'entrepreneurs'
      },
      component: () => import(/* webpackChunkName: "programa-aceleracion-proyectos" */ './views/entrepreneurs/Resources.vue')
    },
    {
      path: '/emprendedores/recursos/aspectos-legales',
      name: 'resource-aspectos-legales',
      meta: {
        layout: 'entrepreneurs'
      },
      component: () => import(/* webpackChunkName: "resource-aspectos-legales" */ './views/entrepreneurs/Resources/AspectosLegales.vue')
    },
    {
      path: '/emprendedores/recursos/publicaciones',
      name: 'resource-publicaciones',
      meta: {
        layout: 'entrepreneurs'
      },
      component: () => import(/* webpackChunkName: "resource-publicaciones" */ './views/entrepreneurs/Resources/Publicaciones.vue')
    },
    {
      path: '/emprendedores/recursos/ayudas-emprendedor',
      name: 'resource-ayudas-emprendedor',
      meta: {
        layout: 'entrepreneurs'
      },
      component: () => import(/* webpackChunkName: "resource-ayudas-emprendedor" */ './views/entrepreneurs/Resources/AyudasEmprendedor.vue')
    },
    {
      path: '/emprendedores/recursos/acuerdos-colaboracion',
      name: 'resource-acuerdos-colaboracion',
      meta: {
        layout: 'entrepreneurs'
      },
      component: () => import(/* webpackChunkName: "resource-acuerdos-colaboracion" */ './views/entrepreneurs/Resources/AcuerdosColaboracion.vue')
    },
    {
      path: '/emprendedores/recursos/ayudas-financiacion',
      name: 'resource-ayudas-financiacion',
      meta: {
        layout: 'entrepreneurs'
      },
      component: () => import(/* webpackChunkName: "resource-ayudas-financiacion" */ './views/entrepreneurs/Resources/AyudasFinanciacion.vue')
    },
    {
      path: '/emprendedores/recursos/premios-emprendedor',
      name: 'resource-premios-emprendedor',
      meta: {
        layout: 'entrepreneurs'
      },
      component: () => import(/* webpackChunkName: "resource-premios-emprendedor" */ './views/entrepreneurs/Resources/PremiosEmprendedor.vue')
    },
    {
      path: '/emprendedores/recursos/simuladores-empresa',
      name: 'resource-simuladores-empresa',
      meta: {
        layout: 'entrepreneurs'
      },
      component: () => import(/* webpackChunkName: "resource-simuladores-empresa" */ './views/entrepreneurs/Resources/SimuladoresPlan.vue')
    },
    {
      path: '/emprendedores/recursos/revistas-emprendedores',
      name: 'resource-revistas-emprendedores',
      meta: {
        layout: 'entrepreneurs'
      },
      component: () => import(/* webpackChunkName: "resource-revistas-emprendedores" */ './views/entrepreneurs/Resources/RevistasEmprendedores.vue')
    },
    {
      path: '/emprendedores/colaboradores',
      name: 'colaboradores',
      meta: {
        layout: 'entrepreneurs'
      },
      component: () => import(/* webpackChunkName: "colaboradores" */ './views/entrepreneurs/Collaborators.vue')
    },
    {
      path: '/spain',
      name: 'spain',
      meta: {
        layout: 'spain'
      },
      component: () => import(/* webpackChunkName: "spain" */ './views/spain/Home.vue')
    },
    {
      path: '/spain/pricing',
      name: 'plans',
      meta: {
        layout: 'spain'
      },
      component: () => import(/* webpackChunkName: "pricing" */ './views/spain/Plans.vue')
    },
    {
      path: '/spain/thanks',
      name: 'plans',
      meta: {
        layout: 'spain'
      },
      component: () => import(/* webpackChunkName: "thanks" */ './views/spain/ThankYou.vue')
    },
    {
      path: '/spain/form',
      name: 'plans',
      meta: {
        layout: 'spain'
      },
      component: () => import(/* webpackChunkName: "form" */ './views/spain/PlanForm.vue')
    },
    {
      path: '/spain/admin',
      name: 'plans',
      meta: {
        layout: 'spain'
      },
      component: () => import(/* webpackChunkName: "admin" */ './views/spain/Admin.vue')
    },
    {
      path: '*',
      name: '404',
      component: () => import(/* webpackChunkName: "404" */ './views/404.vue')
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
  
})

router.beforeEach((to, from, next) => {
  NProgress.start()
  next()
})
router.afterEach(() => {
  NProgress.done(true)
})

export default router;