<template>
  <div class="wrapper entrepreneurs">
    <Header/>
        <!-- <div v-if="$auth.ready()"> -->
          <slot />
        <!-- </div>
        <div v-if="!$auth.ready()">
          Loading ...
        </div> -->
    <Footer/>
  </div>
</template>

<script>
import Header from '../components/entrepreneurs/Header.vue';
import Footer from '../components/Footer.vue';

export default {
  name: 'Default',
  components: {
    Header,
    Footer
  }
}
</script>

<style lang="scss">
  .entrepreneurs {
    h2 {
      font-size: 2.5rem;
      width: 80%;
      border-bottom: 3px solid #009090;
      text-transform: uppercase;
    }

    h3 {
      border-bottom: 3px solid #009090;
      width: 90%;
      text-transform: uppercase;
    }

    @media screen and (max-width: 767px) {
      h2 {
        font-size: 1.5rem;
        width: 100%;
      }

      h3 {
        font-size: 1.3rem;
      }
    }
  }
</style>

    