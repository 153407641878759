<template>
	<div class="wrapper">
		<div id="app">
			<component :is="layout">
				<router-view></router-view>
			</component>
		</div>
		<nprogress-container></nprogress-container>
	</div>
</template>

<script>
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
import NprogressContainer from 'vue-nprogress/src/NprogressContainer';

const default_layout = 'default';

export default {
	name: 'app',
	computed: {
		layout() {
			return (this.$route.meta.layout || default_layout) + '-layout';
		},
	},
	metaInfo() {
		return {
			title: '',
			titleTemplate: 'Club de Ejecutivos',
			meta: [
				// OpenGraph
				{ vmid: 'og:site_name', property: 'og:site_name', content: 'Club de Ejecutivos' },
				{ vmid: 'og:type', property: 'og:type', content: 'website' },
				{ vmid: 'og:url', property: 'og:url', content: window.location.origin + this.$route.path },
				{ vmid: 'og:description', property: 'og:description', content: 'Description about Club de Ejecutivos' },
				// Google / Schema.org markup:
				{ itemprop: 'description', content: 'Description about Club de Ejecutivos' },
				{ itemprop: 'image', content: 'https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/pageheads/empresas.jpg' },
			],
		};
	},
	components: {
		Header,
		Footer,
		NprogressContainer,
	},
};
</script>

<style>
#app {
	font-family: 'Avenir', Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	overflow-x: hidden;
}

.ck.ck-content.ck-editor__editable {
	min-height: 250px;
	text-align: left;
}

/* Remove styles from ckeditor */
.contentp h1,
.contentp h2,
.contentp h3 {
	display: none;
}

.rowas {
	width: 100%;
}

.container {
	width: 80%;
	height: auto;
	margin-left: auto;
	margin-right: auto;
}

.title p {
	font-weight: 300;
	font-size: 34px;
	margin: 10px;
	padding: 10px 0;
	color: #232323;
	text-transform: uppercase;
	font-family: 'open sans condensed', Arial, sans-serif;
	text-align: left;
	letter-spacing: -4px;
}

.title p span {
	font-weight: 700;
	color: #009090;
	margin-top: -12px;
}

figure.image img {
	max-width: 100%;
}

figcaption {
	font-size: 12pt;
	color: #696969;
}

figure.image.image-style-side {
	float: right;
	margin: 0px 10px;
}

.content p figure div {
	width: 100%;
}

.routerLink:hover {
	text-decoration: none;
}

.carousel-caption {
	bottom: 25% !important;
	left: 28% !important;
}
@media (max-width: 425px) {
	.formacionEmpresas {
		letter-spacing: -1px !important;
	}
}
</style>
